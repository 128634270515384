import React, { useState } from "react";
import { ShadowTheme } from "../../ShadowTemplate";
import WaitingDialog from "./components/WaitingDialog";
import BlockBalanceDialog from "./components/BlockBalanceDialog";
import BalanceForm from "./components/BalanceForm";
import SubAccountList from "./components/SubAccountList";
import { showSnackbar } from "../../../shared/jquery_wrapper";
import { parserCurrencyBRLValue } from "../../../shared/helpers";
import { hot } from "react-hot-loader";
import { deposit_admin_account_balance_statement_path } from "../../../../../../app/javascript/routes";
import { parseAmount, calcNewBalance, postAdjuster } from "./balance_service";

const BulkBalance = ({ balance: initialBalance, subAccounts: initialSubAccounts, rootSelector }) => {
  const [showWaitingDialog, setShowWaitingDialog] = useState(false);
  const [showBlockBalanceDialog, setShowBlockBalanceDialog] = useState(false);
  const [balanceAccount, setBalanceAccount] = useState(initialBalance);
  const [list, setList] = useState(initialSubAccounts);

  const updateItems = (subAccountIds, updatedBalance, operation) => {
    const updatedList = list.map((subAccount) =>
      /* istanbul ignore next */
      subAccountIds.includes(subAccount.id) ? { ...subAccount, balance: updateItem(operation, subAccount, updatedBalance) } : subAccount
    );
    return updatedList;
  };

  const updateItem = (operation, subAccount, updatedBalance) => {
    const options = {
      'adjust': updatedBalance,
      'deposit': subAccount.balance + updatedBalance,
      'withdraw': Math.max(0, subAccount.balance - updatedBalance),
    };
    return options[operation];
  };

  const postBalanceAdjuster = async (numberAmount, subAccountIds, operation) => {
    setShowWaitingDialog(true);

    try {
      await postAdjuster(numberAmount, subAccountIds, operation);
      setList(updateItems(subAccountIds, numberAmount, operation));
      showSnackbar({ content: "Saldo em processo de ajuste!", style: "notice" });
    } catch (e) {
      setBalanceAccount(initialBalance);
      setList(initialSubAccounts);
      showSnackbar({ content: "Não foi possível ajustar o saldo, tente novamente!", style: "alert" });
    } finally {
      setShowWaitingDialog(false);
    }
  };

  /* istanbul ignore next */
  const handleUpdate = (amount, operation, withdrawAll = false) => {
    const numberAmount = withdrawAll ? list.reduce((acc, item) => acc + item.balance, 0) : parseAmount(amount);
    const filteredList = list.filter((e) => e.checked);
    const subAccountIds = filteredList.map((e) => e.id);
    const tmpBalance = calcNewBalance(operation, filteredList, numberAmount, balanceAccount, withdrawAll);

    if (tmpBalance >= 0) {
      setBalanceAccount(tmpBalance);
      setShowBlockBalanceDialog(false);
      postBalanceAdjuster(numberAmount, subAccountIds, operation);
    } else {
      setShowBlockBalanceDialog(true);
    }
  };

  return (
    <ShadowTheme rootSelector={rootSelector}>
      <WaitingDialog open={showWaitingDialog} onClose={() => setShowWaitingDialog(false)} />
      <BlockBalanceDialog
        open={showBlockBalanceDialog}
        onClose={() => setShowBlockBalanceDialog(false)}
        onDeposit={() => (window.location.href = deposit_admin_account_balance_statement_path())}
      />
      <BalanceForm
        balance={parserCurrencyBRLValue(balanceAccount)}
        onUpdate={handleUpdate}
        description={'Selecione na lista as subcontas e confirme o valor:'}
      />
      <SubAccountList list={list} setList={setList} />
    </ShadowTheme>
  );
};

export default hot(module)(BulkBalance);
