import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Stack, Typography, Card } from "@mui/material";

const BudgetEmptyStateCard = ({icon, title, subtitle, buttonText, buttonProps}) => {
  const theme = useTheme();

  return (
    <Card sx={{p: 2.0, width: 700}} data-testid="budgets">
      <Stack alignItems={"center"} sx={{ mt: 2.0 }}>
        <Box sx={{ p: 1.5, display: 'flex' }} style={{ backgroundColor: theme.palette.surfaceLight5.main, borderRadius: 5.0 }}>
          {icon}
        </Box>
        <Typography variant="h6" sx={{ mt: 1.0 }}>
          {title}
        </Typography>
        <Typography
            whiteSpace={'pre-line'}
            minHeight={'3em'}
            maxWidth={'860px'}
            textAlign={'center'}
            variant="body1"
            color={"GrayText"}
            sx={{ mt: 1.0 }}>
            {subtitle}
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 1.0 }}
          data-testid="action-button"
          {...buttonProps}
        >
          {buttonText}
        </Button>
      </Stack>
    </Card>
  );
}

export default BudgetEmptyStateCard;