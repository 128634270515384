import React from "react";
import Container from "@mui/material/Container";
import {Button, Card, CardActions, CardContent, CardHeader} from "@mui/material";
import {openModal} from "../../shared/jquery_wrapper";
import {ShadowTheme} from "../ShadowTemplate";
import {hot} from "react-hot-loader";

const MandatoryTwoFactor = ({rootSelector}) => {
    const openTwoFactor = () => openModal('#modal-enable-two-factor');

    return <ShadowTheme rootSelector={rootSelector}>
        <Container sx={{mt: 5}} maxWidth={'md'}>
            <Card>
                <CardHeader
                    title={"Atenção: ativação obrigatória do duplo fator de autenticação!"}/>
                <CardContent>
                    Para reforçar a segurança do sistema, agora é exigida a ativação obrigatória do
                    2FA
                    (Autenticador de Dois Fatores) em todas as contas. Esta medida visa proteger
                    suas
                    informações e impedir acessos não autorizados!
                    <br/><br/>
                    Por favor, faça a ativação imediatamente seguindo as instruções fornecidas.
                </CardContent>
                <CardActions sx={{justifyContent: "flex-end"}}>
                    <Button onClick={openTwoFactor}>Ativar duplo fator de autenticação</Button>
                </CardActions>
            </Card>
        </Container>
    </ShadowTheme>
}

export default hot(module)(MandatoryTwoFactor);