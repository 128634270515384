import {
  DrawerSection,
  NavigationDrawer,
  NavigationItem,
  NavigationSubItem,
} from "./NavigationDrawer";
import * as React from "react";
import {
  admin_account_path,
  admin_advances_path,
  admin_advances_pending_approvals_path,
  edit_admin_security_two_factor_path,
  admin_advances_pending_payments_path,
  admin_advances_pending_releases_path,
  admin_advances_pending_returns_path,
  admin_card_transactions_path,
  admin_classifications_categories_path,
  admin_dashboard_path,
  admin_expenses_path,
  admin_export_erp_path,
  admin_export_expenses_path,
  admin_erp_templates_root_path,
  admin_reports_reviewing_index_path,
  admin_settings_features_path,
  admin_settings_policies_path,
  admin_teams_path,
  admin_users_path,
  approval_admin_reports_pending_path,
  approval_admin_up_fronts_pending_path,
  completion_admin_reports_pending_path,
  pending_admin_reports_path,
  pending_admin_up_fronts_path,
  admin_account_merchants_path,
  admin_account_balance_statement_path,
  admin_account_sub_accounts_path,
  admin_account_banking_apis_path,
  admin_home_path,
  admin_budgets_path,
} from "../../../routes";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  AccountBalance as AccountBalanceIcon,
  BarChart as BarChartIcon,
  ImportExport as ImportExportIcon,
  Payments as PaymentsIcon,
  Person as PersonIcon,
  Power as PowerIcon,
  Home as HomeIcon,
  AttachMoney as AttachMoneyIcon,
  Business as BusinessIcon,
  Settings as SettingsIcon,
  CurrencyExchange as CurrencyExchangeIcon,
} from "@mui/icons-material";
import ApiIcon from "@mui/icons-material/Api";

export default function EspressoDrawer({
  copilotMode,
  roles,
  company,
  account,
  abilities,
  open,
  onClose,
  advancesCount,
  reportsCount,
}) {
  const adminOrFinancial = (roles.isAdmin || roles.isFinancial) && !copilotMode;
  const showDigitalAccount =
    !company.advanceLegacy && (roles.isAdmin || roles.isFinancial);
  const showAdminMenu =
    (roles.isAdmin || roles.isApprover || roles.isFinancial) && !copilotMode;

  return (
    <NavigationDrawer open={open} onClose={onClose}>
      <DrawerSection>
        {adminOrFinancial && (
          <NavigationItem
            label={"Home"}
            icon={<HomeIcon />}
            href={admin_home_path()}
          />
        )}
        <NavigationItem
          label={"Minhas Despesas"}
          icon={<ReceiptIcon />}
          href={admin_expenses_path()}
        />
      </DrawerSection>
      <DrawerSection title={"GESTÃO DE DESPESAS"}>
        <ReportMenus
          copilotMode={copilotMode}
          abilities={abilities}
          reportsCount={reportsCount}
        />
        {company.advanceEnabled && (
          <AdvanceMenus
            company={company}
            abilities={abilities}
            roles={roles}
            advancesCount={advancesCount}
          />
        )}
        {showAdminMenu && (
          <NavigationItem
            label={"Dashboard"}
            icon={<BarChartIcon />}
            href={admin_dashboard_path()}
          />
        )}
      </DrawerSection>
      {showDigitalAccount && !copilotMode && (
        <DrawerSection title={"Conta digital"}>
          <AccountMenus company={company} account={account} />
        </DrawerSection>
      )}
      {
        company.budgetsEnabled && !copilotMode &&
        <DrawerSection>
          <NavigationItem
            label={"Orçamentos"}
            icon={<CurrencyExchangeIcon />}
            href={admin_budgets_path()}
            isNew
          />
        </DrawerSection>
      }
      <IntegrationMenus company={company} showSettingsMenu={adminOrFinancial} />
      {abilities.canExport && <ExportMenus {...abilities} />}
      {roles.isAdmin && adminOrFinancial && (
        <SettingsMenus
          roles={roles}
          abilities={abilities}
          copilotMode={copilotMode}
          showAdminMenu={showAdminMenu}
        />
      )}
    </NavigationDrawer>
  );
}

const ReportMenus = ({ copilotMode, abilities, reportsCount }) => {
  const canApproveReports =
    !copilotMode && abilities.canApprove && reportsCount.toApprove > 0;
  const canCompleteReports =
    !copilotMode && abilities.canComplete && reportsCount.toComplete > 0;

  return (
    <React.Fragment>
      <NavigationItem
        label={"Relatórios"}
        icon={<DescriptionIcon />}
        href={pending_admin_reports_path()}
      />
      {reportsCount.toReview > 0 && (
        <NavigationSubItem
          label={"Para reenviar"}
          badgeContent={reportsCount.toReview}
          href={admin_reports_reviewing_index_path()}
        />
      )}
      {canApproveReports && (
        <NavigationSubItem
          label={"Para aprovar"}
          badgeContent={reportsCount.toApprove}
          href={approval_admin_reports_pending_path()}
        />
      )}
      {canCompleteReports && (
        <NavigationSubItem
          label={"Para concluir"}
          badgeContent={reportsCount.toComplete}
          href={completion_admin_reports_pending_path()}
        />
      )}
    </React.Fragment>
  );
};

const AdvanceMenus = ({ company, abilities, roles, advancesCount }) => {
  const canApproveAdvances =
    abilities.canApprove && advancesCount.toApprove > 0;
  const canPayAdvances =
    roles.isFinancial && !company.advanceLegacy && advancesCount.toPay > 0;
  const canReleaseAdvances =
    roles.isFinancial && !company.advanceLegacy && advancesCount.toRelease > 0;
  const canReturnAdvances =
    !company.advanceLegacy && advancesCount.toReturn > 0;

  const advancePath = company.advanceLegacy
    ? pending_admin_up_fronts_path()
    : admin_advances_path();
  const approvalPath = company.advanceLegacy
    ? approval_admin_up_fronts_pending_path()
    : admin_advances_pending_approvals_path();

  return (
    <React.Fragment>
      <NavigationItem
        label={"Adiantamentos"}
        icon={<PaymentsIcon />}
        href={advancePath}
      />
      {canApproveAdvances && (
        <NavigationSubItem
          label={"Para aprovar"}
          badgeContent={advancesCount.toApprove}
          href={approvalPath}
        />
      )}
      {canPayAdvances && (
        <NavigationSubItem
          label={"Para pagar"}
          badgeContent={advancesCount.toPay}
          href={admin_advances_pending_payments_path()}
        />
      )}
      {canReleaseAdvances && (
        <NavigationSubItem
          label={"Para liberar"}
          badgeContent={advancesCount.toRelease}
          href={admin_advances_pending_releases_path()}
        />
      )}
      {canReturnAdvances && (
        <NavigationSubItem
          label={"Para devolver"}
          badgeContent={advancesCount.toReturn}
          href={admin_advances_pending_returns_path()}
        />
      )}
    </React.Fragment>
  );
};

const AccountMenus = ({ company, account }) => {
  return company.hasAccount ? (
    <React.Fragment>
      <NavigationItem
        label={"Saldos e extratos"}
        icon={<AttachMoneyIcon />}
        href={admin_account_balance_statement_path()}
      />
      <NavigationItem
        label={"Subcontas"}
        icon={<PersonIcon />}
        href={admin_account_sub_accounts_path()}
      />
      <NavigationItem
        label={"Fornecedores"}
        icon={<BusinessIcon />}
        href={admin_account_merchants_path()}
      />
      {account?.hasBankingApi && (
        <NavigationItem
          label={"Banking API"}
          icon={<ApiIcon />}
          href={admin_account_banking_apis_path()}
        />
      )}
    </React.Fragment>
  ) : (
    <NavigationItem
      label={"Abrir conta"}
      icon={<AccountBalanceIcon />}
      href={admin_account_path()}
      isNew
    />
  );
};

const IntegrationMenus = ({ company, showSettingsMenu }) => {
  const subMenus = [
    {
      label: "Cartões e extratos bancários",
      href: admin_card_transactions_path(),
      show: company.integratedCard,
    },
    {
      label: "Templates para ERP’s",
      href: admin_erp_templates_root_path(),
      show: showSettingsMenu,
    },
  ];

  const selected =
    subMenus
      .map((e) => escapeRegex(e.href))
      .findIndex((e) => new RegExp(e).test(window.location.href)) >= 0;

  return company.integratedCard || showSettingsMenu ? (
    <DrawerSection
      title={"Integrações"}
      icon={<PowerIcon />}
      collapse
      open={selected}
    >
      {subMenus.map(
        (e, i) =>
          e.show && <NavigationItem key={i} label={e.label} href={e.href} />
      )}
    </DrawerSection>
  ) : (
    <></>
  );
};

const ExportMenus = ({ canExportExpense, canExportErp }) => {
  const subMenus = [
    {
      label: "Exportação de despesas",
      href: admin_export_expenses_path(),
      show: canExportExpense,
    },
    {
      label: "Exportação para ERP's",
      href: admin_export_erp_path(),
      show: canExportErp,
    },
  ];

  return (
    <DrawerSection
      title={"Exportação"}
      icon={<ImportExportIcon />}
      collapse
      open={
        subMenus
          .map((e) => escapeRegex(e.href))
          .findIndex((e) => new RegExp(e).test(window.location.href)) >= 0
      }
    >
      {subMenus.map(
        (e, i) =>
          e.show && <NavigationItem key={i} label={e.label} href={e.href} />
      )}
    </DrawerSection>
  );
};

const SettingsMenus = ({ abilities, roles, copilotMode, showAdminMenu }) => {
  const subMenus = [
    {
      label: "Funcionalidades",
      href: admin_settings_features_path(),
      show: true,
    },
    {
      label: "Usuários e permissões",
      href: admin_users_path(),
      show: showAdminMenu && abilities.canReadUsers,
    },
    {
      label: "Políticas de despesas",
      href: admin_settings_policies_path(),
      show: true,
    },
    {
      label: "Times e aprovação",
      href: admin_teams_path(),
      show: true,
    },
    {
      label: "Classificação de despesas",
      href: admin_classifications_categories_path(),
      show: true,
    },
    {
      label: "Senhas e Segurança",
      href: edit_admin_security_two_factor_path(),
      show: true,
    },
  ];

  return (
    <DrawerSection
      title={"Configurações"}
      icon={<SettingsIcon />}
      collapse
      open={
        subMenus
          .map((e) => escapeRegex(e.href))
          .findIndex((e) => new RegExp(e).test(window.location.href)) >= 0
      }
    >
      {subMenus.map(
        (e, i) =>
          e.show && <NavigationItem key={i} label={e.label} href={e.href} />
      )}
    </DrawerSection>
  );
};

const escapeRegex = (string) => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
};
