import {Collapse, Divider, ListSubheader, Stack} from "@mui/material";
import {BistroTableFilterItem} from "./BistroTableFilterItem";
import {BistroTableFilterForm} from "./BistroTableFilterForm";
import * as React from "react";
import {useMemo, useState} from "react";

export const BistroTableFilter = ({show, columns, onChange}) => {
    const [filters, setFilters] = useState([]);

    const filterableColumns = useMemo(() => columns.filter(column => {
        return column.filterable && filters.every(f => f.column !== column.id);
    }), [columns, filters]);

    const changeFilters = (newFilters) => {
        setFilters(newFilters);
        onChange(newFilters);
    }

    const handleAddFilter = (filter) => changeFilters([...filters, filter]);

    const handleRemove = (toRemove) => {
        changeFilters(filters.filter(f => f.column !== toRemove.column));
    }

    const handleSave = (newFilter) => {
        changeFilters(filters.map(f => f.column === newFilter.column ? newFilter : f));
    }

    return <Collapse in={show} orientation={'horizontal'}>
        <Stack direction={'row'} sx={{mb: 2, height: '100%', minWidth: 300}}>
            <Divider orientation={'vertical'} sx={{height: '100%'}}/>
            <Stack sx={{mx: 2, flex: 1}}>
                {filters.length > 0 && (
                    <BistroFilterList
                        columns={columns}
                        filters={filters}
                        onSave={handleSave}
                        onRemove={handleRemove}/>
                )}
                {filterableColumns.length > 0 && (
                    <BistroTableFilterForm
                        columns={filterableColumns}
                        filters={filters}
                        onAdd={handleAddFilter}/>
                )}
            </Stack>
        </Stack>
    </Collapse>
}

const BistroFilterList = ({columns, filters, onRemove, onSave}) => {
    const filterColumn = (columnId) => columns.find(column => column.id === columnId);

    return <>
        <ListSubheader aria-label={'Filtros ativos'}>{'Filtros ativos'}</ListSubheader>
        {[...filters.map(filter => (
            <BistroTableFilterItem
                key={filter.column}
                column={filterColumn(filter.column)}
                value={filter.value}
                onSave={onSave}
                onRemove={() => onRemove(filter)}/>
        ))]}
    </>
}