import React from "react";
import classNames from "classnames";

import CardType from "../parsers/CardType";
import ModalSubscription from "../../shared/modals/Subscription";
import FormSubscription from "../forms/Subscription";
import ModalSubAccountCancel from "../../shared/modals/SubAccountCancel";
import Pagination from "../../shared/Pagination";
import Loading from "../../shared/Loading";
import { parserCurrencyBRLValue } from "../../shared/helpers";

class SubscriptionCards extends React.Component {
  state = {
    selected: {},
    unblock: false,
    updateModal: false,
    showList: true,
    cards: [],
    forcePageOne: false,
    loading: false,
  };

  componentDidMount() {
    if (this.props.hasCards) {
      this.setState({ forcePageOne: !this.state.forcePageOne });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.can_manage && (
          <React.Fragment>
            <ModalSubscription
              testAccount={this.props.testAccount}
              update={this.state.updateModal}
              card={this.state.selected}
              endpoints={this.props.endpoints}
              balance_account={this.props.balance_account}
              unblock={this.state.unblock}
              can_manage={this.props.can_manage}
              images={this.props.images}
              showList={(show) => this.setState({ showList: show })}
            />
            <ModalSubAccountCancel
              card={this.state.selected}
              endpoints={this.props.endpoints}
            />
          </React.Fragment>
        )}

        <div className="subscription-cards">
          {this.props.hasCards && (
            <div
              className={classNames("collection-item", {
                hide: !this.state.showList,
              })}
            >
              <Loading show={this.state.loading} />
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Número</th>
                    <th>Tipo</th>
                    <th>Recorrência</th>
                    <th>Saldo</th>
                    <th>Status</th>
                    {this.props.can_manage && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {this.state.cards.map((card, idx) => (
                    <tr
                      key={idx}
                      className={classNames({
                        disabled: !card.active,
                        blocked: card.blocked,
                      })}
                    >
                      <td>{card.description}</td>
                      <td>Final {card.number}</td>
                      <td>
                        <CardType virtual={card.virtual} />
                      </td>
                      <td>{card.deposit_interval_parsed}</td>
                      <td>{parserCurrencyBRLValue(card.balance)}</td>
                      <td>{card.status}</td>
                      {this.props.can_manage && this.props.account_active && (
                        <td className="collection-item action">
                          {card.status === "Bloqueado" ? (
                            <div className="d-flex align-center">
                              <div
                                test-name="unblock-button"
                                className="d-flex unblock-option modal-trigger"
                                href="#modal-subscription"
                                onClick={() => this.handleModal(card, true)}
                              >
                                <span>Desbloquear</span>
                                <i className="mdi mdi-lock " />
                              </div>

                              <i
                                test-name="cancel-button"
                                className="mdi mdi-delete cancel-btn modal-trigger"
                                href="#modal-cancel-subscription"
                                onClick={() => this.handleModal(card)}
                              />
                            </div>
                          ) : (
                            <i
                              test-name="detail-button"
                              className={classNames(
                                "mdi mdi-dots-vertical more",
                                {
                                  "modal-trigger": card.active,
                                }
                              )}
                              href="#modal-subscription"
                              onClick={() => this.handleModal(card, false)}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <FormSubscription
            {...this.props}
            showList={(show) => this.setState({ showList: show })}
          />
          <div
            className={classNames("collection-item", {
              hide: !this.state.showList,
            })}
          >
            <Pagination
              perPage={5}
              forcePageOne={this.state.forcePageOne}
              url={this.props.endpoints.cards}
              setExternalList={(list) =>
                this.setState({ cards: list, loading: false })
              }
              setExternalLoading={(show) => this.setState({ loading: show })}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleModal(card, unblock) {
    this.setState({
      updateModal: !this.state.updateModal,
      selected: card,
      unblock: unblock,
    });
  }
}

SubscriptionCards.defaultProps = {
  testAccount: false,
  hasCards: false,
  balance_account: "",
  can_manage: true,
  account_active: true,
  endpoints: {
    edit: "",
    block: "",
    unblock: "",
    cancel: "",
    token: "",
    cards: "",
  },
  images: {
    virtual_card_icon: "",
    physical_card_icon: "",
    mastercard_logo: "",
    espresso_card_logo: "",
  },
};

export default SubscriptionCards;
