import React, { useState } from "react";
import { ShadowTheme } from "../ShadowTemplate";
import { hot } from "react-hot-loader";
import { Button, Card, CardActions, CardContent, Snackbar, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { parserCurrencyBRLValue, parserValueWithBrlCurrency } from "../../shared/helpers";
import { parseAmount } from "../account/multi_balance/balance_service";
import { UpdateBudgetDto, updateBudget } from "./budgets_service";

const EditBudgetShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <EditBudget
      csrfToken={props.csrfToken}
      id={props.id}
      limit={props.limit}
    />
  </ShadowTheme>
}

const EditBudget = ({ csrfToken, id, limit }) => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);
  const [newLimit, setNewLimit] = useState(parserCurrencyBRLValue(limit));
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleNewLimit = (event) => {
    setNewLimit(parserValueWithBrlCurrency(event.target.value));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const dto = new UpdateBudgetDto(parseAmount(newLimit));
    await updateBudget(
      csrfToken,
      id,
      dto,
      () => setOpenSnackbar(true),
      () => setLoading(false),
    );
  }
  
  return <>
    <Card sx={{ mx: smallScreen ? "1rem" : "10rem", p: 1 }}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Typography>Ajuste o valor do orçamento</Typography>
          <TextField
            sx={{ my: 2.5, width: smallScreen ? "100%" : "50%" }}
            label="Valor (R$)"
            variant="outlined"
            size="medium"
            value={newLimit}
            onChange={handleNewLimit}
            placeholder="R$ 0,00"
            disabled={loading}
            data-testid={'limit-field'}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: "end" }}>
          <Button
            type="submit"
            variant="contained"
            fullWidth={smallScreen}
            disabled={parseAmount(newLimit) == 0 || loading}
          >
            Salvar alterações
          </Button>
        </CardActions>
      </form>
    </Card>
    <Snackbar
      open={openSnackbar}
      message={'O ajuste do orçamento foi realizado com sucesso!'}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  </>
}

export default hot(module)(EditBudgetShadow);