import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, CircularProgress, Paper, Typography } from "@mui/material";

const WaitingDialog = ({
  open,
  onClose,
  title = "Ajustando o saldo",
  content = "Leva apenas alguns instantes."
}) => {
  return (
    <Dialog open={open}>
      <Paper>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            data-testid="close-dialog-success"
            onClick={onClose}
          >
            fechar
          </Button>
          <Box display={"flex"} justifyContent={"center"} width={40}>
            <CircularProgress size={24} color="success" />
          </Box>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default WaitingDialog;
