import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import TableSkeleton from "./TableSkeleton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnArchiveIcon from '@mui/icons-material/Unarchive';

const TransactionsTable = ({
  rows,
  totalRows,
  page,
  type,
  loading,
  selectAll,
  selectAllTransactions,
  selectedTransactions,
  handleCheckboxChange,
  handleRowClick,
  handleOpenDialog,
  handleOpenUserSelectionModal,
  handleChangePage
}) => {
  const confirmTitle = type === 'unarchived' ? 'Deseja arquivar a transação selecionada?' : 'Deseja desarquivar a transação selecionada?';
  const confirmDesciption = type == 'unarchived' ? 'A transação não precisará ser comprovada e será movida para a lista de arquivados.' : 'Uma vez desarquivada, a transação será novamente visível na  lista de transações.'

  const handleUserRowClick = (row) => {
    if (row.user_name) {
      handleRowClick(row)
    } else {
      handleOpenUserSelectionModal(row.id)
    }
  };

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <TableSkeleton />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={selectAllTransactions}
                      data-testid="checkbox-select-all"
                    />
                  }
                />
              </TableCell>
              <TableCell>USUÁRIO</TableCell>
              <TableCell>VALOR</TableCell>
              <TableCell>DATA</TableCell>
              <TableCell>ESTABELECIMENTO</TableCell>
              <TableCell>CARTÃO</TableCell>
              {type == 'unarchived' && (
                <TableCell>COMPROVADA?</TableCell>
              )}

              {type == 'unarchived' ? (
                <TableCell>ARQUIVAR</TableCell>
              ) : (
                <TableCell>DESARQUIVAR</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <FormControlLabel
                    key={row.id}
                    control={
                      <Checkbox
                        checked={selectedTransactions.includes(row.id)}
                        onChange={() => handleCheckboxChange(row.id)}
                        disabled={row.proven}
                        data-testid={`checkbox-${row.id}`}
                      />
                    }
                  />
                </TableCell>
                <TableCell onClick={() => handleUserRowClick(row)}>
                  {row.user_name ? (
                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                      <img src={row.photo_profile_url} width={40} />
                      {row.user_name}
                    </div>
                  ) : (
                    <span
                      style={{maxWidth: '120px', cursor: 'pointer', borderBottom: '1px dashed rgba(0, 0, 0, 0.38)'}}
                    >
                      Nenhum usuário selecionado
                    </span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleRowClick(row)}>{row.cost}</TableCell>
                <TableCell onClick={() => handleRowClick(row)}>{row.date}</TableCell>
                <TableCell onClick={() => handleRowClick(row)}>{row.merchant_name}</TableCell>
                <TableCell onClick={() => handleRowClick(row)}>{row.last_digits}</TableCell>
                {type == 'unarchived' && (
                  <TableCell onClick={() => handleRowClick(row)} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {row.proven ? (
                      <CheckCircleIcon style={{ color: '#00CBA8' }} data-testid="check-circle-icon"  />
                    ) : (
                      <RemoveDoneIcon />
                    )}
                  </TableCell>
                )}
                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {row.proven ? (
                    <Tooltip title="Transações comprovadas não necessitam ser arquivadas">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  ) : (
                    type == "unarchived" ? (
                      <IconButton
                        onClick={() => handleOpenDialog(row.id, confirmTitle, confirmDesciption)}
                        aria-label="Arquivar"
                        data-testid={`archive-button-${row.id}`}
                      >
                        <ArchiveIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleOpenDialog(row.id, confirmTitle, confirmDesciption)}
                        aria-label="Desarquivar"
                        data-testid={`unarchive-button-${row.id}`}
                      >
                        <UnArchiveIcon />
                      </IconButton>
                    )
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={totalRows}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                data-testid="pagination"
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </TableContainer>
  );
};

export default TransactionsTable;