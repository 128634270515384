import React, { useState, useEffect } from 'react';
import { Stack, Box, Card, TextField, Select, MenuItem, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Pagination, CircularProgress, InputAdornment, Typography, Divider, Snackbar, Collapse, Grid, FormControl } from '@mui/material';
import { ShadowTheme } from "../ShadowTemplate";
import SearchIcon from '@mui/icons-material/Search';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavBar from './common/NavBar';
import { expenses_admin_budget_path, admin_report_path }  from "../../../routes";
import { parserCurrencyBRLValue } from '../../shared/helpers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import _ from 'lodash';

const ExpensesList = (props) => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    userName: '',
    categoryId: '',
    status: '',
    start: '',
    end: ''
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [validStartFilter, setValidStartFilter] = useState(true);
  const [validEndFilter, setValidEndFilter] = useState(true);

  useEffect(() => {
    fetchExpenses();
  }, [page, triggerFetch]);

  const fetchExpenses = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          const mappedKey = _.snakeCase(key);
          queryParams.append(`filter[${mappedKey}]`, filters[key]);
        }
      });

      const response = await fetch(`${expenses_admin_budget_path(props.budgetId)}?${queryParams.toString()}&page=${page}`, {
        headers: {
          "X-CSRF-Token": props.csrfToken,
          'Accept': 'application/json'
        }
      });
      const data = await response.json();
      setExpenses(data.expenses);
      setTotalPages(data.total_pages);
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage(`Ocorreu um erro ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  const handleStartFilter = (date) => {
    setFilters({ ...filters, start: date.format('YYYY-MM-DD') });
    setValidStartFilter(date.isValid());
  }

  const handleEndFilter = (date) => {
    setFilters({ ...filters, end: date.format('YYYY-MM-DD') });
    setValidEndFilter(date.isValid());
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    setTriggerFetch(true);
  };

  const handleClearFilters = () => {
    setFilters({
      userName: '',
      categoryId: '',
      status: '',
      start: '',
      end: '',
    });
    setTriggerFetch(!triggerFetch);
  };

  const handleFilterClick = () => {
    setTriggerFetch(!triggerFetch);
  };

  const renderStatusText = (status) => {
    switch (status) {
      case 1:
      case 2:
        return <Typography sx={{ color: '#2E7D32' }}>Aprovada</Typography>;
      case 0:
        return <Typography sx={{ color: '#FF9800' }}>Em aprovação</Typography>;
    }
  };

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <NavBar filters={filters} budgetId={props.budgetId} />
      <Stack alignItems={"center"} sx={{marginTop: 3}}>
        <Card sx={{p: 2.0, mb: 2, width: '90%'}}>
          <Box sx={{ p: 2 }}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ marginBottom: 5 }}
            >
              <Typography variant="h5">
                Lista de despesas
              </Typography>
              <IconButton onClick={() => setShowFilters((show) => !show)}>
                <FilterListIcon data-testid={"filters-icon"}/>
              </IconButton>
            </Stack>
            <Collapse
              in={showFilters}
              timeout={300}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="Buscar nome do usuário"
                    name="userName"
                    value={filters.userName}
                    onChange={handleFilterChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                    sx={{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    variant="outlined"
                    displayEmpty
                    name="categoryId"
                    value={filters.categoryId}
                    onChange={handleFilterChange}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value="" disabled>Selecione a categoria</MenuItem>
                    <MenuItem value="1">Alimentação</MenuItem>
                    <MenuItem value="2">Transporte</MenuItem>
                    <MenuItem value="3">Hospedagem</MenuItem>
                    <MenuItem value="4">Outros</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    variant="outlined"
                    displayEmpty
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                    sx={{width: '100%'}}
                  >
                    <MenuItem value="" disabled>Selecione o status</MenuItem>
                    <MenuItem value="1,2">Aprovada</MenuItem>
                    <MenuItem value="0">Em aprovação</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ px: 1 }}>
                    Período
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider
                      adapterLocale="pt-br"
                      dateAdapter={AdapterDayjs}
                    >
                      <DatePicker
                        disableHighlightToday
                        label="Data início"
                        format={'DD/MM/YYYY'}
                        value={dayjs(filters.start)}
                        onChange={handleStartFilter}
                        slots={{ openPickerIcon: ArrowDropDownIcon }}
                        data-testid={"start-field"}
                        slotProps={{
                          textField: {
                            error: !validStartFilter,
                            helperText: !validStartFilter && "Data inválida"
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider
                      adapterLocale="pt-br"
                      dateAdapter={AdapterDayjs}
                    >
                      <DatePicker
                        disableHighlightToday
                        label="Data fim"
                        format={'DD/MM/YYYY'}
                        value={dayjs(filters.end)}
                        onChange={handleEndFilter}
                        slots={{ openPickerIcon: ArrowDropDownIcon }}
                        data-testid={"end-field"}
                        slotProps={{
                          textField: {
                            error: !validEndFilter,
                            helperText: !validEndFilter && "Data inválida"
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button variant="outlined" onClick={handleClearFilters}>Limpar</Button>
                    <Button variant="contained" color="primary" onClick={handleFilterClick}>Filtrar</Button>
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{marginTop: '25px'}} />
            </Collapse>

            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TableContainer component={Paper} sx={{ mt: showFilters && 5 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Valor (R$)</TableCell>
                        <TableCell>Data de criação</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Usuário</TableCell>
                        <TableCell>Relatório</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenses.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{parserCurrencyBRLValue(row.cost, false)}</TableCell>
                          <TableCell>{row.localized_date}</TableCell>
                          <TableCell>{renderStatusText(row.report_status)}</TableCell>
                          <TableCell>{row.user_name}</TableCell>
                          <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>
                              #{row.report_number}
                            </Typography>
                            <IconButton
                              target='_blank'
                              href={admin_report_path(row.report_token, { expense: row.id })}
                            >
                              <OpenInNewIcon color='primary'/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Pagination
                    shape='rounded'
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              </>
            )}
          </Box>
        </Card>
      </Stack>

      <Snackbar
        data-testid="snackbar"
        open={openSnackbar}
        message={snackbarMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
      />
    </ShadowTheme>
  );
};

export default ExpensesList;

