import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { ShadowTheme } from "../../ShadowTemplate";
import { admin_security_two_factor_path } from '../../../../routes';

const TwoFactorConfig = (props) => {
  const [switchChecked, setSwitchChecked] = useState(props.active);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSwitchChange = async () => {
    let state = switchChecked ? 'desativado' : 'ativado';

    try {
      const response = await fetch(admin_security_two_factor_path(), {
        method: 'PATCH',
        headers: {
          "X-CSRF-Token": props.csrfToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ active: !switchChecked })
      });

      /* istanbul ignore next */
      if (response.ok) {
        setSnackbarMessage(`Duplo fator de autenticação ${state}`);
        setOpenSnackbar(true);

        setSwitchChecked(!switchChecked);
      }
    } catch (error) {
      setSnackbarMessage(`Ocorreu um erro ${error.message}`);
      setOpenSnackbar(true);
    }
  };

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <Card sx={{ marginTop: '80px' }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            Duplo fator de autenticação
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body2" color="text.secondary">
                Reforce a segurança de seus usuários e de sua empresa exigindo a configuração do duplo fator de autenticação.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Ao ativar, você vai tornar a configuração do duplo fator obrigatória para todos os colaboradores da empresa a partir do próximo login.
              </Typography>
              { props.active && props.hasAccount ? <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                *A autenticação de dois fatores não poderá ser desativada, isso garante um nível de segurança superior para sua conta digital.
              </Typography> : <Typography variant="body2" fontWeight="bold" sx={{ mt: 1 }}>
                Atenção: Para garantir um nível de segurança superior, aos usuários de conta digital após a ativação essa funcionalidade não poderá ser desativada.
              </Typography> }
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end" alignItems="center">
              <Typography variant="body2" color="text.secondary">
              {switchChecked ? 'Ativado' : 'Desativado'}
              </Typography>
              <Switch
                checked={switchChecked}
                onChange={handleSwitchChange}
                disabled={props.active && props.hasAccount}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Snackbar
        data-testid="snackbar"
        open={openSnackbar}
        autoHideDuration={6000}
        message={snackbarMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
      />
    </ShadowTheme>
  )
}

export default TwoFactorConfig;