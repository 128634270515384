import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper, Typography } from "@mui/material";

const BlockBalanceDialog = ({
  open,
  onClose,
  onDeposit,
  title = "Ooops!",
  content = "A conta não tem saldo suficiente, por isso não será possível seguir com a ação.",
}) => {
  return (
    <Dialog open={open}>
      <Paper>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>
            {content}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={onClose}
          >
            Fechar
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#3D0079", color: "white" }}
            onClick={onDeposit}
          >
            Adicionar saldo
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default BlockBalanceDialog;
