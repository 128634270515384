import React from "react";
import { ShadowTheme } from "../ShadowTemplate";
import { AppBar, Fab, Stack, Toolbar, Typography } from "@mui/material";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BudgetEmptyStateCard from "./common/BudgetEmptyCardContent"
import BudgetsList from "./BudgetsList"
import { openModal } from "../../shared/jquery_wrapper";
import { new_admin_budget_path } from "../../../routes";
import AddIcon from '@mui/icons-material/Add';

const getEmptyStateCardContent = (buttonText, buttonProps) => (
  <BudgetEmptyStateCard
    icon={<CurrencyExchangeIcon color="primary" />}
    title={"Conheça os recursos avançados de Orçamentos!"}
    subtitle={"Orçamentos são a espinha dorsal da sua gestão financeira, permitindo uma visão clara dos gastos e receitas. Com nossa atualização, você pode criar orçamentos pontuais para projetos e capacidade de edição pós criação."}
    buttonText={buttonText}
    buttonProps={buttonProps}
  />
);

const BudgetsPage = (props) => {
  let content;

  if (!props.budgetsEnabled) {
    content = getEmptyStateCardContent("FAZER UPGRADE DE PLANO", { onClick: () => openModal('#upgrade') });
  } else if (props.emptyState) {
    content = getEmptyStateCardContent("CRIAR ORÇAMENTO", { href: new_admin_budget_path() });
  } else {
    content = <BudgetsList admin={props.admin}/>;
  }
  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: '#F0EBF5',
          boxShadow: 'none'
        }}
      >
        <Toolbar>
          <Typography sx={{ color: 'black', fontSize: '18px' }}>
              Orçamentos
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack alignItems={"center"} sx={{ mt: 2.0 }}>
        {content}
      </Stack>
      {
        props.budgetsEnabled && !props.emptyState && props.admin &&
        <Fab
          color="primary"
          data-testid={'new-budget-fab'}
          aria-label={'Novo orçamento'}
          href={new_admin_budget_path()}
          sx={{ position: 'fixed', right: 30, bottom: 30 }}
        >
          <AddIcon/>
        </Fab>
      }
    </ShadowTheme>
  );
}

export default BudgetsPage;