import {grey} from "@mui/material/colors";
import {styled} from "@mui/material/styles";


const EspressoMain = styled('main', {shouldForwardProp: (prop) => prop !== 'drawerOpen'})(
    ({theme, drawerOpen}) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: grey[200],
        height: '100vh',
        overflowY: 'auto',
        marginLeft: 0,
        ...(drawerOpen && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 240,
        }),
    }),
)

export default EspressoMain