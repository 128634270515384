import React, {useRef, useState} from 'react';
import {ShadowTheme} from "../../../admin/ShadowTemplate";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';
import {
    BistroTable,
    BistroTableAction,
    BistroTableCell,
    BistroTableColumn,
    BistroTableRow
} from "../../common/table/BistroTable";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import {
    addWorkflowMembers,
    fetchWorkflowMembers,
    removeWorkflowMembers,
    replaceWorkflowMembers
} from "./workflow_service";
import {hot} from "react-hot-loader";
import {JobProgressDialog} from "../../common/JobProgressDialog";
import {SingleFieldDialog} from "../../common/SingleFieldDialog";

dayjs.extend(localizedFormat);
dayjs.locale('pt-br');

const UserWorkflowsShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <UserWorkflows {...props}/>
    </ShadowTheme>
}

const UserWorkflows = (props) => {
    const {
        userId,
        companyId,
        csrfToken,
        canManage,
        data = [],
        currentPage,
        totalCount,
        perPage,
        pollingInterval
    } = props;
    const [email, setEmail] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [memberIds, setMemberIds] = useState([]);
    const [selectedCount, setSelectedCount] = useState(0);

    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [currentAction, setCurrentAction] = useState('add');
    const [filters, setFilters] = useState([]);

    const tableRef = useRef(null);

    const fetchPage = async (page, perPage, filters) => {
        setFilters(filters);
        const body = await fetchWorkflowMembers(csrfToken, companyId, userId, page, perPage, filters);
        return {...body, data: workflowMemberRows(body.data)}
    };

    const openEmailDialog = (action) => (selectAll, memberIds, selectedCount) => {
        setCurrentAction(action);
        setSelectAll(selectAll);
        setMemberIds(memberIds);
        setSelectedCount(selectedCount)
        setEmailDialogOpen(true);
    };
    const closeEmailDialog = () => setEmailDialogOpen(false);
    const afterEmailDialog = (action) => (email) => {
        closeEmailDialog();
        setEmail(email);
        openConfirmDialog(action)(selectAll, memberIds, selectedCount);
    };

    const openConfirmDialog = (action) => (selectAll, memberIds, selectedCount) => {
        setCurrentAction(action);
        setSelectAll(selectAll);
        setMemberIds(memberIds);
        setSelectedCount(selectedCount)
        setConfirmDialogOpen(true);
    };
    const closeConfirmDialog = () => setConfirmDialogOpen(false);
    const handleComplete = () => {
        tableRef.current.refresh(filters);
        closeConfirmDialog();
    }

    const jobRequests = {
        'add': () => addWorkflowMembers(csrfToken, companyId, userId, selectAll, memberIds, email, filters),
        'replace': () => replaceWorkflowMembers(csrfToken, companyId, userId, selectAll, memberIds, email, filters),
        'remove': () => removeWorkflowMembers(csrfToken, companyId, userId, selectAll, memberIds, filters),
    }

    const validateEmail = (email) => {
        if (email.match(emailRegex)) return;

        return 'Email inválido';
    }

    return <>
        <BistroTable
            ref={tableRef}
            title={'Fluxos de Aprovação'}
            hoverRows={true}
            selectable={canManage}
            columns={workflowMemberColumns}
            data={workflowMemberRows(data)}
            totalCount={totalCount}
            perPage={perPage}
            initialPage={currentPage}
            fetchPage={fetchPage}
            emptyMessage={'Este usuário não participa de nenhum workflow'}
            actions={[
                BistroTableAction({
                    id: 'add-approver',
                    width: 224,
                    icon: <PersonAddIcon/>,
                    tooltip: 'Adiciona um novo aprovador aos fluxos selecionados',
                    label: 'Adicionar aprovador',
                    onClick: openEmailDialog('add'),
                }),
                BistroTableAction({
                    id: 'remove-approver',
                    width: 216,
                    icon: <PersonRemoveIcon/>,
                    tooltip: 'Remove o aprovador dos fluxos selecionados',
                    label: 'Remover aprovador',
                    onClick: openConfirmDialog('remove'),
                }),
                BistroTableAction({
                    id: 'replace-approver',
                    width: 224,
                    icon: <SocialDistanceIcon/>,
                    tooltip: 'Substitui o aprovador por outro nos fluxos selecionados',
                    label: 'Substituir aprovador',
                    onClick: openEmailDialog('replace'),
                })
            ]}/>
        <SingleFieldDialog
            open={emailDialogOpen}
            onCancel={closeEmailDialog}
            onConfirm={afterEmailDialog(currentAction)}
            validator={validateEmail}
            title={dialogs[currentAction]?.title}
            content={dialogs[currentAction]?.emailMessage}/>
        {confirmDialogOpen && <JobProgressDialog
          csrfToken={csrfToken}
          title={dialogs[currentAction]?.title}
          open={confirmDialogOpen}
          pollingInterval={pollingInterval}
          onCancel={closeConfirmDialog}
          onComplete={handleComplete}
          fetchJobId={jobRequests[currentAction]}
          loadingMessage={dialogs[currentAction]?.loadingMessage}
          renderSuccess={() => dialogs[currentAction]?.successMessage}
          renderPartialSuccess={dialogs[currentAction]?.partialSuccessMessage}
          renderInitial={() => dialogs[currentAction]?.confirmMessage(selectedCount)}
          renderFail={dialogs[currentAction]?.failMessage}/>}
    </>
}

const dialogs = {
    'add': {
        title: 'Adicionar aprovador',
        emailMessage: 'Insira abaixo email do novo aprovador a ser adicionado aos fluxos selecionado. O aprovador fará parte nos mesmos níveis do usuário atual',
        confirmMessage: (count) => `Confirma a adição do aprovador ${count > 1 ? `aos ${count} fluxos selecionados?` : 'ao fluxo selecionado?'}`,
        loadingMessage: 'Adicionando aprovador ao fluxos de aprovação. Aguarde...',
        successMessage: 'Aprovador adicionado aos fluxos de aprovação com sucesso',
        failMessage: (error) => `Falha ao adicionar o aprovador. ${error ?? ''}`,
        partialSuccessMessage: (error) => `Operação realizada. O aprovador não pôde ser adicionado aos seguintes fluxos: ${error}`,
    },
    'replace': {
        title: 'Substituir aprovador',
        emailMessage: 'Insira abaixo email do aprovador que substituirá o usuário atual nos fluxos selecionados.',
        confirmMessage: (count) => `Confirma a substituição do aprovador ${count > 1 ? `nos ${count} fluxos selecionados?` : 'no fluxo selecionado?'}`,
        loadingMessage: 'Substituindo aprovador nos fluxos de aprovação. Aguarde...',
        successMessage: 'Aprovador substituído nos fluxos de aprovação com sucesso',
        failMessage: (error) => `Falha ao substituir o aprovador. ${error ?? ''}`,
        partialSuccessMessage: (error) => `Operação realizada. O aprovador não pôde ser substituído nos seguintes fluxos: ${error}`,
    },
    'remove': {
        title: 'Remover aprovador',
        confirmMessage: (count) => `Confirma a remoção do aprovador ${count > 1 ? `dos ${count} fluxos selecionados?` : 'do fluxo selecionado?'}`,
        loadingMessage: 'Removendo o aprovador dos fluxos de aprovação. Aguarde...',
        successMessage: 'Aprovador removido dos fluxos de aprovação com sucesso',
        failMessage: (error) => `Falha ao remover o aprovador. ${error ?? ''}`,
        partialSuccessMessage: (error) => `Operação realizada. O aprovador não pôde ser removido dos seguintes fluxos: ${error}`,
    }
}

const workflowMemberRows = (members) => {
    return members.map((member) => BistroTableRow({
        id: member.id,
        data: member,
        cells: new Map([
            ['workflow_id', BistroTableCell({value: member.workflowId})],
            ['member_id', BistroTableCell({value: member.id})],
            ['workflow_name', BistroTableCell({value: member.workflowName})],
            ['workflow_target', BistroTableCell({value: member.workflowTarget})],
            ['level_description', BistroTableCell({value: member.levelDescription})],
            ['single_member', BistroTableCell({value: member.singleMember ? 'Sim' : 'Não'})],
            ['created_at', BistroTableCell({value: dayjs(member.createdAt).format('LLL')})],
        ])
    }));
}


const workflowMemberColumns = [
    BistroTableColumn({id: 'workflow_id', padding: 'none', label: 'Workflow'}),
    BistroTableColumn({id: 'member_id', label: 'Membro'}),
    BistroTableColumn({id: 'workflow_name', label: 'Time', filterable: true}),
    BistroTableColumn({
        id: 'workflow_target',
        label: 'Tipo do Workflow',
        filterable: true,
        filterType: 'select',
        values: [
            {value: 0, label: 'Relatório'},
            {value: 1, label: 'Adiantamento'},
            {value: 2, label: 'Alçada de Aprovação'}
        ]
    }),
    BistroTableColumn({id: 'level_description', label: 'Nível'}),
    BistroTableColumn({id: 'single_member', label: 'Único no nível'}),
    BistroTableColumn({id: 'created_at', label: 'Desde'}),
];

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default hot(module)(UserWorkflowsShadow);