import { balance_admin_account_sub_accounts_multi_balances_path } from "../../../../routes";

export const parseAmount = (amount) => Number(amount.replace("R$", "").replace(/\./g, "").replace(",", "."));

export const calcWithdraw = (balanceAccount, numberAmount, subAccounts, withdrawAll) => {
  let totalAdded = 0;
  subAccounts.forEach(subAccount => {
    const rescueAmount = withdrawAll ? subAccount.balance : Math.min(subAccount.balance, numberAmount);
    totalAdded += rescueAmount;
  });
  return Number(balanceAccount) + totalAdded;
};

export const calcNewBalance = (operation, subAccounts, numberAmount, balanceAccount, withdrawAll) => {
  const options = {
    'adjust': subAccounts.reduce((acc, { balance }) => acc + balance - numberAmount, Number(balanceAccount)),
    'deposit': (Number(balanceAccount) - (numberAmount * subAccounts.length)),
    'withdraw': calcWithdraw(balanceAccount, numberAmount, subAccounts, withdrawAll)
  };
  return options[operation];
};

const dataPostAdjuste = (amount, subAccountIds, operation) => {
  return {
    amount: amount,
    sub_account_ids: subAccountIds,
    operation: operation
  };
};

export const postAdjuster = async (amount, subAccountIds, operation) => {
  try {
    await $.ajax({
      type: "POST",
      dataType: "json",
      url: balance_admin_account_sub_accounts_multi_balances_path(),
      data: dataPostAdjuste(amount, subAccountIds, operation),
    });
  } catch (error) {
    throw error;
  }
};
