import React from "react";

class Pagination extends React.Component {
  state = {
    atualPage: 1,
    perPage: this.props.perPage,
    totalCount: 0,
    totalPage: 0,
    loading: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.forcePageOne !== prevProps.forcePageOne) {
      this.setState({ ...this.state, atualPage: 1 });
      this.changePage(1);
    }
  }

  render() {
    return (
      this.state.totalCount > this.state.perPage && (
        <ul test-name="pagination-list" className="pagination">
          {this.state.atualPage === 1 ? (
            this.renderDisableItem(
              <i className="mdi mdi-chevron-left" />,
              "previous-page"
            )
          ) : (
            <li>
              <a
                test-name="previous-page"
                href={`#${this.state.atualPage - 1}`}
                onClick={() => this.changePage(this.state.atualPage - 1)}
              >
                <i className="mdi mdi-chevron-left" />
              </a>
            </li>
          )}

          {this.state.atualPage > 5 && this.renderDisableItem("...")}

          {[1, 2, 3, 4]
            .filter((e) => e < this.state.atualPage)
            .map((el, idx, ar) =>
              ar.length === 4
                ? this.renderPageItem(el + this.state.atualPage - 5, idx)
                : this.renderPageItem(el, idx)
            )}

          <li className="active">
            <a test-name={`page-${this.state.atualPage}`}>
              {this.state.atualPage}
            </a>
          </li>

          {[1, 2, 3, 4]
            .filter((e) => e + this.state.atualPage <= this.state.totalPage)
            .map((el, idx) =>
              this.renderPageItem(el + this.state.atualPage, idx)
            )}

          {this.state.totalPage - this.state.atualPage > 4 &&
            this.renderDisableItem("...")}

          {this.state.totalPage === this.state.atualPage ? (
            this.renderDisableItem(
              <i className="mdi mdi-chevron-right" />,
              "next-page"
            )
          ) : (
            <li>
              <a
                test-name="next-page"
                href={`#${this.state.atualPage + 1}`}
                onClick={() => this.changePage(this.state.atualPage + 1)}
              >
                <i className="mdi mdi-chevron-right" />
              </a>
            </li>
          )}
        </ul>
      )
    );
  }

  //Custom Methods

  changePage(newPage) {
    if (this.state.loading) {
      return;
    } else {
      this.setState({ ...this.state, loading: true });
      this.props.setExternalLoading(true);
      $.ajax({
        type: "GET",
        dataType: "json",
        url: this.props.url,
        data: { ...this.props.params, page: newPage },
      })
        .then((r) => {
          this.props.setExternalList(r.data.list, r.data);
          this.setState({
            ...this.state,
            loading: false,
            atualPage: Number(r.data.current_page),
            totalCount: r.data.total_count,
            totalPage: Math.ceil(r.data.total_count / this.state.perPage),
          });
        })
        .catch((e) => {
          if (e.status === 401) {
            window.location.assign("/");
          }
          this.setState({ ...this.state, loading: false });

          window.alert("ocorreu um erro ao buscar a listagem");
        });
    }
  }

  renderDisableItem(children, testName) {
    return (
      <li className="disabled">
        <a test-name={testName}>{children}</a>
      </li>
    );
  }

  renderPageItem(page, idx) {
    return (
      <li key={idx}>
        <a
          test-name={`page-${page}`}
          href={`#page=${page}`}
          onClick={() => this.changePage(page)}
        >
          {page}
        </a>
      </li>
    );
  }
}

Pagination.defaultProps = {
  url: "",
  perPage: 10,
  setExternalList: (list, others) => {},
  setExternalLoading: () => {},
  params: {},
};

export default Pagination;
