import React, { useState } from 'react';
import { Card, List, ListItem, Checkbox, ListItemText, Pagination } from '@mui/material';
import { parserCurrencyBRLValue } from "../../../../shared/helpers";

const SubAccountList = ({ list, setList }) => {
  const [page, setPage] = useState(1);
  const rowsPerPage = 25;

  const handleCheck = (id, isChecked) => {
    const newList = list.map(item => item.id === id ? { ...item, checked: !isChecked } : item);
    setList(newList);
  };

  const handleAllCheck = (isChecked) => {
    const newList = list.map(item => ({ ...item, checked: !isChecked }));
    setList(newList);
  };

  const currentPageItems = list.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const allChecked = list.length > 0 && list.every(item => item.checked);

  return (
    <Card sx={{ mt: 3 }}>
      <List>
        <ListItem
          sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)", display: "flex", flexDirection: "row" }}
        >
          <Checkbox
            checked={allChecked}
            onChange={() => handleAllCheck(allChecked)}
            inputProps={{ 'aria-label': 'select all subaccounts' }}
          />
          <ListItemText primary={<b>Subcontas</b>} sx={{ flex: 1 }} />
          <ListItemText primary={<b>Saldo disponível</b>} sx={{ flex: 1 }} />
        </ListItem>
        {currentPageItems.map((item) => (
          <ListItem
            key={item.id}
            sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)", display: "flex", flexDirection: "row" }}
          >
            <Checkbox
              data-testid={`checkbox-id-${item.id}`}
              checked={item.checked ?? false}
              onChange={() => handleCheck(item.id, item.checked)}
              inputProps={{ 'aria-label': `select subaccount ${item.id}` }}
            />
            <ListItemText primary={item.name} sx={{ flex: 1 }} />
            <ListItemText
              data-testid={`balance-item-${item.id}`}
              primary={parserCurrencyBRLValue(item.balance)}
              sx={{ flex: 1 }}
            />
          </ListItem>
        ))}
      </List>
      <Pagination
        sx={{ mb: 3, ml: 3 }}
        count={Math.ceil(list.length / rowsPerPage)}
        page={page}
        onChange={(event, value) => setPage(value)}
        color="primary"
        shape="rounded"
      />
    </Card>
  );
};

export default SubAccountList;
